@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* stylelint-disable */
@import "variables";
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
/* stylelint-enable */

body {
  font-family: 'Courier New', serif;
  font-size: 18px;
  padding: 2rem 8rem;
  color: white;

  @media (max-width: 480px) {
    padding: 2rem 3rem;
  }

  a {
    color: white;
  }
}

header {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  body {
    padding: 2rem;
  }
}

h1 {
  font-size: 1.5rem;
  font-weight: $weight-normal;
}

.fragment {
  display: none;

  &.active {
    display: block;
  }
}

.home {
  .photo-caption {
    position: fixed;
    bottom: 50px;
    text-align: center;
    padding: 0 8rem;
    margin-left: -8rem;
    width: 100%;
    font-size: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
    position: fixed;
    top: 2rem;
    text-align: center;
    width: 100%;
    margin-left: -8rem;
    letter-spacing: 4rem;
    font-weight: $weight-bold;
  }

  @media (max-width: 1280px) {
    .title {
      letter-spacing: 2.6rem;
    }
  }

  @media (max-width: 768px) {
    .title {
      letter-spacing: 2.0rem;
      text-align: right;
      padding-right: 1rem;
      margin-left: -2rem;
    }

    .photo-caption {
      margin-left: -2rem;
      padding: 0 2rem;
    }
  }

  @media (max-width: 480px) {
    .title {
      letter-spacing: 1rem;
    }
  }

  @media (max-width: 320px) {
    .title {
      letter-spacing: 0.5rem;
    }
  }
}

.page-home {
  background: url("../images/background.webp") no-repeat center center fixed;
  background-size: cover;

  body {
    color: $index-color;

    a {
      color: $index-color;
    }
  }
}

.about {
  h2,
  h3 {
    font-weight: $weight-bold;
  }

  .title {
    font-size: 1.5rem;
  }

  .press-list {
    min-width: 225px;

    li {
      clear: both;
      padding-bottom: 0.5rem;

      .a span:first-child {
        padding-right: 1rem;
      }

      .icon {
        font-size: 0.9rem;
        line-height: 1.75rem;
        padding-left: 0.5rem;
      }
    }
  }
}

.works .work {
  padding-bottom: 2rem;

  .title {
    text-align: right;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  ul {
    text-align: right;
  }

  img {
    width: 282px;
    margin-left: 2rem;
  }
}

#menu a {
  font-size: 1.5rem;
}
