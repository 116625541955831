$family-sans-serif: "Roboto", sans-serif;

$body-background-color: black;
$body-color: black;
$text: white;

$main-box-height: 700px;
$main-box-width: 950px;

$link: white;
$link-hover: white;

$weight-light: 100;
$weight-normal: 300;
$weight-bold: 900;

$index-color: #6d4c43;
